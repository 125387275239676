import React from 'react';
import PropTypes from 'prop-types';

import './HeaderHole.css';

const HeaderHole = ({ screenSize }) => (
  <div className={`header-hole-${screenSize}`} />
);

HeaderHole.propTypes = {
  screenSize: PropTypes.string.isRequired,
};

export default HeaderHole;
