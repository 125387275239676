import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Carousel.css';

const Carousel = ({ pictures }) => {
  const [index, setIndex] = useState(0);

  const moveBackward = () => {
    const newIndex = index - 1 < 0 ? pictures.length - 1 : index - 1;
    setIndex(newIndex);
  };

  const moveForward = () => {
    const newIndex = index + 1 > pictures.length - 1 ? 0 : index + 1;
    setIndex(newIndex);
  };

  return (
    <div className="carousel">
      <img src={pictures[index]} alt={pictures[index]} />
      {pictures.length > 1
        && (
        <>
          <button
            className="prev"
            type="button"
            onClick={moveBackward}
          >
            &#10094;
          </button>
          <button
            className="next"
            type="button"
            onClick={moveForward}
          >
            &#10095;
          </button>
        </>
        )}
    </div>
  );
};

Carousel.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Carousel;
