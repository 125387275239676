import React from 'react';
import PropTypes from 'prop-types';

import './Footer.css';

const Footer = ({ screenSize }) => (
  <div className={`badge-section badge-footer-${screenSize}`}>
    <h3>Rua Maria da Luz Rocha Belão, 229, c 05 - Xaxim - Curitiba</h3>
  </div>
);

Footer.propTypes = {
  screenSize: PropTypes.string.isRequired,
};

export default Footer;
