import React from 'react';
import PropTypes from 'prop-types';

import './Alligator.css';

import clip from '../img/jacare.png';

const Alligator = ({ screenSize }) => (
  <div className={`alligator-${screenSize}`}>
    <img src={clip} alt="prendedor de crachá estilo jacaré" />
  </div>
);

Alligator.propTypes = {
  screenSize: PropTypes.string.isRequired,
};

export default Alligator;
