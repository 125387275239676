import React from 'react';
import PropTypes from 'prop-types';

import './HeaderButton.css';

const HeaderButton = ({ screenSize, isQuoteOpen, onClick }) => (
  <div className={`badge-section header-button-${screenSize}`}>
    {!isQuoteOpen
      && (
      <button
        className={`button-${screenSize}`}
        type="button"
        onClick={onClick}
      >
        PEDIR ORÇAMENTO
      </button>
      )}
  </div>
);

HeaderButton.propTypes = {
  screenSize: PropTypes.string.isRequired,
  isQuoteOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HeaderButton;
