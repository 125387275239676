/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import './Quote.css';

import animation from '../img/sending.gif';

const Quote = ({ screenSize, isQuoteOpen, onClose }) => {
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const {
    register, handleSubmit, errors, clearErrors,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = async (data) => {
    setSending(true);
    await fetch(`${process.env.REACT_APP_BASE_URL}/quote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    setSending(false);
    setSent(true);
  };

  const handleOnClick = () => {
    setSending(false);
    setSent(false);
    onClose();
  };

  return (
    isQuoteOpen
      && (
      <div className={`badge-section badge-quote-${screenSize}`}>
        <div
          className={`close-${screenSize}`}
          onClick={handleOnClick}
        >
          <svg
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="#333"
            strokeWidth="3"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
        {sent
          ? (
            <div className="message-sent">
              <h3>Mensagem enviada!</h3>
              <p>Entraremos em contato em breve.</p>
            </div>
          )
          : (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="field">
                  <label htmlFor="name">
                    <p className="label">Nome</p>
                    <input
                      type="text"
                      name="name"
                      ref={register({
                        required: 'Por favor, digite seu nome',
                      })}
                      onChange={() => clearErrors('name')}
                    />
                    {errors.name
                      && (
                      <p className="error">
                        &#9888;
                        &nbsp;
                        {errors.name.message}
                      </p>
                      )}
                  </label>
                </div>

                <div className="field">
                  <label htmlFor="email">
                    <p className="label">E-mail</p>
                    <input
                      type="text"
                      name="email"
                      ref={register({
                        required: 'Por favor, digite seu e-mail',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Parece que este e-mail não é válido',
                        },
                      })}
                      onChange={() => clearErrors('email')}
                    />
                    {errors.email
                      && (
                      <p className="error">
                        &#9888;
                        &nbsp;
                        {errors.email.message}
                      </p>
                      )}
                  </label>
                </div>

                <div className="field">
                  <label htmlFor="tel">
                    <p className="label">Telefone (opcional)</p>
                    <input type="text" name="tel" ref={register()} />
                  </label>
                </div>

                <div className="field">
                  <label htmlFor="company">
                    <p className="label">Empresa (opcional)</p>
                    <input type="text" name="company" ref={register()} />
                  </label>
                </div>

                <div className="field">
                  <label htmlFor="message">
                    <p className="label">Mensagem</p>
                    <textarea
                      name="message"
                      ref={register({
                        required: 'Por favor, escreva uma mensagem',
                      })}
                      onChange={() => clearErrors('message')}
                    />
                    {errors.message
                      && (
                      <p className="error">
                        &#9888;
                        &nbsp;
                        {errors.message.message}
                      </p>
                      )}
                  </label>
                </div>

                <input type="submit" value="Enviar" />

              </form>
            </>
          )}
        {sending
          && (
            <img className="animation" src={animation} alt="" />
          )}
      </div>
      )
  );
};

Quote.propTypes = {
  screenSize: PropTypes.string.isRequired,
  isQuoteOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Quote;
