import React from 'react';
import PropTypes from 'prop-types';

import './HeaderLogo.css';

const HeaderLogo = ({ screenSize }) => (
  <div className={`badge-section header-logo-${screenSize}`}>
    <div>
      <h2>C & C</h2>
      <h1>Crachás & Cia</h1>
    </div>
  </div>
);

HeaderLogo.propTypes = {
  screenSize: PropTypes.string.isRequired,
};

export default HeaderLogo;
