import React from 'react';
import PropTypes from 'prop-types';

import './Header.css';

import HeaderButton from './HeaderButton';
import HeaderHole from './HeaderHole';
import HeaderInfo from './HeaderInfo';
import HeaderLogo from './HeaderLogo';

const Header = ({ screenSize, isQuoteOpen, onClickQuoteButton }) => (
  <div className={`badge-header-${screenSize}`}>
    <HeaderButton
      screenSize={screenSize}
      isQuoteOpen={isQuoteOpen}
      onClick={onClickQuoteButton}
    />
    <HeaderHole screenSize={screenSize} />
    <HeaderInfo screenSize={screenSize} />
    <HeaderLogo screenSize={screenSize} />
  </div>
);

Header.propTypes = {
  screenSize: PropTypes.string.isRequired,
  isQuoteOpen: PropTypes.bool.isRequired,
  onClickQuoteButton: PropTypes.func.isRequired,
};

export default Header;
