import React from 'react';
import PropTypes from 'prop-types';

import './Container.css';

const Container = ({ children, screenSize }) => (

  <div className={`container-${screenSize}`}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  screenSize: PropTypes.string.isRequired,
};

export default Container;
