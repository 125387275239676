import React from 'react';
import PropTypes from 'prop-types';

import Carousel from './Carousel';

import './Card.css';

const Card = ({
  title, text, pictures, screenSize,
}) => (
  <div className={`card-${screenSize}`}>
    <div className="info">
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
    <Carousel pictures={pictures} />
  </div>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  pictures: PropTypes.arrayOf(PropTypes.string).isRequired,
  screenSize: PropTypes.string.isRequired,
};

export default Card;
