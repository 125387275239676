import React, { useEffect, useState } from 'react';

import Alligator from './components/Alligator';
import Container from './components/Container';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';
import Quote from './components/Quote';

function App() {
  const [screenSize, setScreenSize] = useState('small');
  const [isQuoteOpen, setIsQuoteOpen] = useState(false);

  const updateDimensions = () => {
    const width = window.innerWidth;
    if (width < 700) {
      setScreenSize('small');
    } else if (width < 1100) {
      setScreenSize('medium');
    } else {
      setScreenSize('large');
    }
  };

  const toggleQuote = () => {
    setIsQuoteOpen(!isQuoteOpen);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <Container screenSize={screenSize}>
      <Alligator screenSize={screenSize} />
      <Header
        screenSize={screenSize}
        isQuoteOpen={isQuoteOpen}
        onClickQuoteButton={toggleQuote}
      />
      <Main
        screenSize={screenSize}
        isQuoteOpen={isQuoteOpen}
      />
      <Quote
        screenSize={screenSize}
        isQuoteOpen={isQuoteOpen}
        onClose={toggleQuote}
      />
      <Footer screenSize={screenSize} />
    </Container>
  );
}

export default App;
