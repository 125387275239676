import React from 'react';
import PropTypes from 'prop-types';

import Card from './Card';

import './Main.css';

import cracha1 from '../img/cracha1.png';
import cracha2 from '../img/cracha2.png';
import cracha3 from '../img/cracha3.png';
import cracha4 from '../img/cracha4.png';
import carteirinha1 from '../img/carteirinha1.png';
import carteirinha2 from '../img/carteirinha2.png';
import carteirinha3 from '../img/carteirinha3.png';
import cordao1 from '../img/cordao1.png';
import cordao2 from '../img/cordao2.png';
import credencial1 from '../img/credencial1.png';
import credencial2 from '../img/credencial2.png';
import credencial3 from '../img/credencial3.png';
import comanda1 from '../img/comanda1.png';
import comanda2 from '../img/comanda2.png';
import rollerclip1 from '../img/rollerclip1.png';
import bottom1 from '../img/bottom1.png';
import fita1 from '../img/fita1.png';
import placa1 from '../img/placa1.png';

const Main = ({ screenSize, isQuoteOpen }) => {
  const cardInfo = [
    {
      id: 0,
      title: 'CRACHÁ',
      text: 'Crachás customizados com foto, nome, função, com chip de aproximação e/ou código de barras.',
      pictures: [cracha1, cracha2, cracha3, cracha4],
    },
    {
      id: 1,
      title: 'CARTEIRINHA DE ESTUDANTE',
      text: 'Carteirinhas conforme modelo da escola. Pode conter código de barras e/ou chip de aproximação.',
      pictures: [carteirinha1, carteirinha2, carteirinha3],
    },
    {
      id: 2,
      title: 'CORDÃO',
      text: 'Pode ser confeccionado especialmente com o nome e logo da sua empresa.',
      pictures: [cordao1, cordao2],
    },
    {
      id: 3,
      title: 'CREDENCIAL PARA EVENTO',
      text: 'Credenciais personalizadas ou conforme um dos modelos de nosso portfólio.',
      pictures: [credencial1, credencial2, credencial3],
    },
    {
      id: 4,
      title: 'COMANDA',
      text: 'Comandas personalizadas com logo, código de barras ou QR code.',
      pictures: [comanda1, comanda2],
    },
    {
      id: 5,
      title: 'ROLLER CLIP',
      text: 'Prendedores retráteis de várias cores para crachás. Também podem ser personalizados.',
      pictures: [rollerclip1],
    },
    {
      id: 6,
      title: 'BOTTOM',
      text: 'Podem ser personalizados em vários formatos e modelos.',
      pictures: [bottom1],
    },
    {
      id: 7,
      title: 'FITA PERSONALIZADA',
      text: 'Fitas para casamento com impressão digital. Fazemos com até 85 cm de comprimento.',
      pictures: [fita1],
    },
    {
      id: 8,
      title: 'PLACA DE SINALIZAÇÃO',
      text: 'Dos mais variados tipos e tamanhos, de acordo com a necessidade da sua empresa.',
      pictures: [placa1],
    },
  ];

  return (
    !isQuoteOpen
      && (
      <div className={`badge-section badge-main-${screenSize}`}>
        {cardInfo.map((card) => (
          <Card
            key={card.id}
            screenSize={screenSize}
            title={card.title}
            text={card.text}
            pictures={card.pictures}
          />
        ))}
      </div>
      )
  );
};

Main.propTypes = {
  screenSize: PropTypes.string.isRequired,
  isQuoteOpen: PropTypes.bool.isRequired,
};

export default Main;
