import React from 'react';
import PropTypes from 'prop-types';

import './HeaderInfo.css';

const HeaderInfo = ({ screenSize }) => (
  <div className={`badge-section header-info-${screenSize}`}>
    <h1>crachas@crachasecia.com.br</h1>
    <h2>(41) 3359-2820</h2>
    <h3>(41) 99876-8700</h3>
  </div>
);

HeaderInfo.propTypes = {
  screenSize: PropTypes.string.isRequired,
};

export default HeaderInfo;
